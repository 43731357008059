// al final de tot hi ha un export
function llista_ways() {
var ways = [
{
  "type": "way",
  "id": 4785281,
  "center": {
    "lat": 41.3811562,
    "lon": 2.1744594
  },
  "tags": {
    "created_by": "Potlatch 0.6",
    "highway": "pedestrian",
    "name": "Carrer d'en Quintana",
    "surface": "paving_stones"
  }
},
{
  "type": "way",
  "id": 8956284,
  "center": {
    "lat": 41.5422022,
    "lon": 2.4500635
  },
  "tags": {
    "created_by": "Potlatch alpha",
    "highway": "residential",
    "name": "Carrer de Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 23936929,
  "center": {
    "lat": 41.5367421,
    "lon": 2.0992092
  },
  "tags": {
    "highway": "residential",
    "maxspeed": "30",
    "name": "Carrer de Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 50357196,
  "center": {
    "lat": 41.2267916,
    "lon": 0.5402338
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer Alberto Quintana"
  }
},
{
  "type": "way",
  "id": 54118657,
  "center": {
    "lat": 41.3726620,
    "lon": 2.0860937
  },
  "tags": {
    "highway": "residential",
    "lit": "yes",
    "name": "Carrer de Josep Miquel Quintana",
    "oneway": "yes",
    "surface": "asphalt"
  }
},
{
  "type": "way",
  "id": 56694256,
  "center": {
    "lat": 41.4528085,
    "lon": 2.2443164
  },
  "tags": {
    "highway": "living_street",
    "lanes": "1",
    "name": "carrer de la Quintana Alta",
    "name:wikipedia": "ca:Quintana",
    "oneway": "yes",
    "sorting_name": "Quintana Alta, carrer de la",
    "foto": "carrer_quintana_alta_barcelona.jpg"
  }
},
{
  "type": "way",
  "id": 67550912,
  "center": {
    "lat": 42.0014848,
    "lon": 2.2842361
  },
  "nodes": [
    815604387,
    815604868
  ],
  "tags": {
    "highway": "living_street",
    "name": "Plaça de la Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 127679245,
  "center": {
    "lat": 41.5791770,
    "lon": 2.5466020
  },
  "tags": {
    "highway": "residential",
    "lanes": "1",
    "name": "Camí de Can Quintana",
    "oneway": "yes",
    "surface": "asphalt"
  }
},
{
  "type": "way",
  "id": 131073062,
  "center": {
    "lat": 41.5184014,
    "lon": 2.3601092
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 140273689,
  "center": {
    "lat": 42.1309114,
    "lon": 3.0083238
  },
  "tags": {
    "building": "yes",
    "name": "Mas de la Quintana"
  }
},
{
  "type": "way",
  "id": 153457463,
  "center": {
    "lat": 41.7860556,
    "lon": 1.8382553
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de Quintana"
  }
},
{
  "type": "way",
  "id": 157736669,
  "center": {
    "lat": 41.5990116,
    "lon": 2.4458181
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de la Quintana",
    "oneway": "no",
    "source": "PNOA",
    "source:date": "2009"
  }
},
{
  "type": "way",
  "id": 162354036,
  "center": {
    "lat": 41.7362762,
    "lon": 1.9761735
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de la Quintana"
  }
},
{
  "type": "way",
  "id": 167271088,
  "center": {
    "lat": 41.6420988,
    "lon": 1.9968663
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de la Quintana de Can Pèlecs",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 167642710,
  "center": {
    "lat": 41.9278427,
    "lon": 2.0514782
  },
  "tags": {
    "building": "farm",
    "name": "la Quintana"
  }
},
{
  "type": "way",
  "id": 167642742,
  "center": {
    "lat": 41.9282993,
    "lon": 2.0544183
  },
  "tags": {
    "access": "yes",
    "bicycle": "yes",
    "bridge": "yes",
    "foot": "yes",
    "highway": "track",
    "horse": "yes",
    "motor_vehicle": "yes",
    "name": "Pont de la Quintana",
    "surface": "concrete",
    "tracktype": "grade1"
  }
},
{
  "type": "way",
  "id": 167642753,
  "center": {
    "lat": 41.9274947,
    "lon": 2.0518570
  },
  "tags": {
    "bicycle": "yes",
    "foot": "yes",
    "highway": "unclassified",
    "horse": "yes",
    "motor_vehicle": "yes",
    "name": "Camí de la Quintana",
    "surface": "ground",
    "tracktype": "grade2"
  }
},
{
  "type": "way",
  "id": 175760888,
  "center": {
    "lat": 41.6974426,
    "lon": 2.1970665
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de Quintana"
  }
},
{
  "type": "way",
  "id": 178775948,
  "center": {
    "lat": 41.3119039,
    "lon": 1.3597129
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Quintana del Castell"
  }
},
{
  "type": "way",
  "id": 178775963,
  "center": {
    "lat": 41.3118470,
    "lon": 1.3596991
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Quintana del Castell"
  }
},
{
  "type": "way",
  "id": 178849718,
  "center": {
    "lat": 41.1751746,
    "lon": 1.3243227
  },
  "tags": {
    "highway": "residential",
    "lanes": "1",
    "name": "Carrer de la Quintana del Castell",
    "oneway": "no",
    "surface": "paved"
  }
},
{
  "type": "way",
  "id": 185547711,
  "center": {
    "lat": 41.9332546,
    "lon": 2.2238155
  },
  "tags": {
    "building": "yes",
    "name": "La Quintana del Grau"
  }
},
{
  "type": "way",
  "id": 185981206,
  "center": {
    "lat": 41.8762412,
    "lon": 2.2878494
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer d'en Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 185981210,
  "center": {
    "lat": 41.8762359,
    "lon": 2.2870896
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 203062908,
  "center": {
    "lat": 42.0681970,
    "lon": 2.9130349
  },
  "tags": {
    "access": "yes",
    "bicycle": "yes",
    "cycleway": "no",
    "foot": "yes",
    "highway": "residential",
    "horse": "no",
    "maxspeed": "30",
    "name": "Carrer Quintana",
    "oneway": "no",
    "sidewalk": "right"
  }
},
{
  "type": "way",
  "id": 203062978,
  "center": {
    "lat": 42.0685625,
    "lon": 2.9145989
  },
  "tags": {
    "access": "yes",
    "bicycle": "yes",
    "cycleway": "no",
    "foot": "yes",
    "highway": "residential",
    "horse": "no",
    "maxspeed": "30",
    "name": "Carrer Quintana",
    "oneway": "no",
    "sidewalk": "both"
  }
},
{
  "type": "way",
  "id": 233761626,
  "center": {
    "lat": 41.9206511,
    "lon": 2.3156077
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de la Quintana",
    "surface": "asphalt"
  }
},
{
  "type": "way",
  "id": 233761628,
  "center": {
    "lat": 41.9188524,
    "lon": 2.3150831
  },
  "tags": {
    "landuse": "industrial",
    "name": "Polígon industrial de la Quintana"
  }
},
{
  "type": "way",
  "id": 234677725,
  "center": {
    "lat": 41.3108580,
    "lon": 1.3591463
  },
  "tags": {
    "highway": "residential",
    "name": "Quintana de l'Església"
  }
},
{
  "type": "way",
  "id": 246923064,
  "center": {
    "lat": 42.3239662,
    "lon": 2.0472064
  },
  "tags": {
    "highway": "residential",
    "name": "Pujada de la Quintana"
  }
},
{
  "type": "way",
  "id": 254154129,
  "center": {
    "lat": 41.9184096,
    "lon": 2.0451454
  },
  "tags": {
    "access": "yes",
    "bicycle": "yes",
    "foot": "yes",
    "highway": "unclassified",
    "horse": "yes",
    "motor_vehicle": "yes",
    "name": "Camí de la Quintana",
    "surface": "ground",
    "tracktype": "grade2"
  }
},
{
  "type": "way",
  "id": 283423100,
  "center": {
    "lat": 42.4520147,
    "lon": 0.7072911
  },
  "tags": {
    "building": "residential",
    "name": "Casa Quintana"
  }
},
{
  "type": "way",
  "id": 311379114,
  "center": {
    "lat": 41.4380290,
    "lon": 2.1614710
  },
  "tags": {
    "highway": "footway",
    "name": "Camí de la Font d'en Quintana"
  }
},
{
  "type": "way",
  "id": 312144481,
  "center": {
    "lat": 41.9281360,
    "lon": 2.2598977
  },
  "tags": {
    "leisure": "park",
    "name": "Parc d'Armand Quintana Panedas"
  }
},
{
  "type": "way",
  "id": 318079202,
  "center": {
    "lat": 41.7376578,
    "lon": 1.9744724
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de la Quintana"
  }
},
{
  "type": "way",
  "id": 328877199,
  "center": {
    "lat": 41.9643165,
    "lon": 2.7396725
  },
  "tags": {
    "highway": "service",
    "name": "Plaça de la Quintana"
  }
},
{
  "type": "way",
  "id": 350202644,
  "center": {
    "lat": 42.2720462,
    "lon": 2.6238588
  },
  "tags": {
    "building": "yes",
    "name": "la Quintana"
  }
},
{
  "type": "way",
  "id": 391527013,
  "center": {
    "lat": 41.3677077,
    "lon": 1.8002999
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de la Quintana"
  }
},
{
  "type": "way",
  "id": 392367740,
  "center": {
    "lat": 41.3559688,
    "lon": 2.0824679
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de Quintana i Milars",
    "oneway": "no"
  }
},
{
  "type": "way",
  "id": 403176891,
  "center": {
    "lat": 41.3672793,
    "lon": 1.8007899
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de la Quintana"
  }
},
{
  "type": "way",
  "id": 446822134,
  "center": {
    "lat": 41.6432751,
    "lon": 2.2760356
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintana de Can Màrgens"
  }
},
{
  "type": "way",
  "id": 480748935,
  "center": {
    "lat": 41.6480388,
    "lon": 2.5007705
  },
  "tags": {
    "area": "yes",
    "description": "Masia",
    "name": "La Quintana (masia)",
    "source": "Ajuntament de Vallgorguina"
  }
},
{
  "type": "way",
  "id": 524122209,
  "center": {
    "lat": 41.4449005,
    "lon": 2.1784861
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Passatge Quintana"
  }
},
{
  "type": "way",
  "id": 552856124,
  "center": {
    "lat": 41.7220339,
    "lon": 2.3530320
  },
  "tags": {
    "building": "yes",
    "name": "Can Quintana",
    "place": "isolated_dwelling",
    "foto": "can_quintana_canoves.jpg"
  }
},
{
  "type": "way",
  "id": 554644443,
  "center": {
    "lat": 41.9251503,
    "lon": 2.0463449
  },
  "tags": {
    "access": "yes",
    "bicycle": "yes",
    "foot": "yes",
    "highway": "unclassified",
    "horse": "yes",
    "motor_vehicle": "yes",
    "name": "Camí de la Quintana"
  }
},
{
  "type": "way",
  "id": 640133493,
  "center": {
    "lat": 41.3111092,
    "lon": 1.3593594
  },
  "tags": {
    "highway": "steps",
    "incline": "up",
    "name": "Quintana de l'Església"
  }
},
{
  "type": "way",
  "id": 640133554,
  "center": {
    "lat": 41.3118690,
    "lon": 1.3596891
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Quintana del Castell"
  }
},
{
  "type": "way",
  "id": 661604068,
  "center": {
    "lat": 41.9291772,
    "lon": 2.0556759
  },
  "tags": {
    "bicycle": "yes",
    "foot": "yes",
    "highway": "unclassified",
    "horse": "yes",
    "motor_vehicle": "yes",
    "name": "Camí de la Quintana",
    "surface": "gravel",
    "tracktype": "grade2"
  }
},
{
  "type": "way",
  "id": 664200254,
  "center": {
    "lat": 41.5387702,
    "lon": 2.0982233
  },
  "tags": {
    "highway": "residential",
    "maxspeed": "30",
    "name": "Carrer de Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 669759776,
  "center": {
    "lat": 42.0408714,
    "lon": 3.1245378
  },
  "tags": {
    "leisure": "garden",
    "name": "Jardins de Can Quintana"
  }
},
{
  "type": "way",
  "id": 687617727,
  "center": {
    "lat": 42.0887155,
    "lon": 2.6182035
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Torrent de Pla Quintana",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 705956722,
  "center": {
    "lat": 42.3257586,
    "lon": 2.0466545
  },
  "tags": {
    "highway": "unclassified",
    "name": "Pujada de la Quintana"
  }
},
{
  "type": "way",
  "id": 747403223,
  "center": {
    "lat": 41.4976867,
    "lon": 0.8875473
  },
  "tags": {
    "building": "yes",
    "name": "Cabana de Cal Quintana"
  }
},
{
  "type": "way",
  "id": 751013449,
  "center": {
    "lat": 41.2546715,
    "lon": 1.5906788
  },
  "tags": {
    "highway": "living_street",
    "name": "Carrer de la Quintana"
  }
},
{
  "type": "way",
  "id": 794844234,
  "center": {
    "lat": 42.2716306,
    "lon": 2.6300831
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de la Quintana",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 794845855,
  "center": {
    "lat": 42.2629594,
    "lon": 2.6058082
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Torrent de la Quintana",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 796243613,
  "center": {
    "lat": 42.0015520,
    "lon": 2.2842912
  },
  "tags": {
    "highway": "living_street",
    "name": "Plaça de la Quintana",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 815895496,
  "center": {
    "lat": 41.4369705,
    "lon": 0.6303888
  },
  "tags": {
    "building": "yes",
    "name": "Mas de Quintana"
  }
},
{
  "type": "way",
  "id": 925458902,
  "center": {
    "lat": 41.7645883,
    "lon": 2.1467200
  },
  "tags": {
    "leisure": "park",
    "name": "Parc de la Quintana"
  }
},
{
  "type": "way",
  "id": 67509816,
  "center": {
    "lat": 41.9916338,
    "lon": 2.9556708
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Quintà"
  }
},
{
  "type": "way",
  "id": 119759736,
  "center": {
    "lat": 41.9029422,
    "lon": 2.4990213
  },
  "tags": {
    "highway": "unclassified",
    "name": "carretera de Mas Quintà"
  }
},
{
  "type": "way",
  "id": 156618462,
  "center": {
    "lat": 42.3315176,
    "lon": 2.3135095
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Quintà"
  }
},
{
  "type": "way",
  "id": 278775647,
  "center": {
    "lat": 41.8981442,
    "lon": 2.4939421
  },
  "tags": {
    "name": "Riera del Mas Quintà",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 631344926,
  "center": {
    "lat": 41.7854290,
    "lon": 2.3218081
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Quintà de la Caseta",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 743074153,
  "center": {
    "lat": 41.9010951,
    "lon": 2.4938358
  },
  "tags": {
    "highway": "unclassified",
    "name": "carretera de Mas Quintà"
  }
},
{
  "type": "way",
  "id": 743074154,
  "center": {
    "lat": 41.9028553,
    "lon": 2.4978712
  },
  "nodes": [
    2080810760,
    1344529140
  ],
  "tags": {
    "bridge": "yes",
    "highway": "unclassified",
    "layer": "1",
    "name": "carretera de Mas Quintà"
  }
},
{
  "type": "way",
  "id": 780276504,
  "center": {
    "lat": 42.1130065,
    "lon": 2.0691704
  },
  "tags": {
    "landuse": "meadow",
    "name": "Quintà dels Plans"
  }
},
{
  "type": "way",
  "id": 780276513,
  "center": {
    "lat": 42.1157873,
    "lon": 2.0753495
  },
  "tags": {
    "landuse": "meadow",
    "name": "Quintà del Molí"
  }
},
{
  "type": "way",
  "id": 140273689,
  "center": {
    "lat": 42.07934,
    "lon": 3.09570
  },
  "tags": {
    "building": "yes",
    "name": "Mas d'en Quintana"
  }
},
{
  "type": "way",
  "id": 780276513,
  "center": {
    "lat": 42.12356,
    "lon": 2.07962
  },
  "tags": {
    "landuse": "meadow",
    "name": "Quintà de Petja"
  }
},
{
  "type": "way",
  "id": 1023707136,
  "center": {
    "lat": 41.74486,
    "lon": 2.27922
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Vilardebò"
  }
},
{
  "type": "way",
  "id": 1023707138,
  "center": {
    "lat": 41.74547,
    "lon": 2.29011
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintana del Solà"
  }
},
{
  "type": "way",
  "id": 1023707139,
  "center": {
    "lat": 41.74046,
    "lon": 2.29169
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintana del Coll"
  }
},
{
  "type": "way",
  "id": 1026137920,
  "center": {
    "lat": 42.0346238,
    "lon": 2.2066937
  },
  "tags": {
    "building": "yes",
    "name": "Mas Quintanes"
  }
},
{
  "type": "way",
  "id": 175766141,
  "center": {
    "lat": 41.7008552,
    "lon": 2.2156765
  },
  "tags": {
    "building": "yes",
    "name": "Can Quintanes"
  }
},
{
  "type": "way",
  "id": 183654898,
  "center": {
    "lat": 41.2448117,
    "lon": 1.4870675
  },
  "tags": {
    "highway": "living_street",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 200205095,
  "center": {
    "lat": 41.2868873,
    "lon": 1.7214771
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de les Quintanes"
  }
},
{
  "type": "way",
  "id": 380117115,
  "center": {
    "lat": 41.2871444,
    "lon": 1.7210590
  },
  "tags": {
    "highway": "residential",
    "name": "Plaça de les Quintanes"
  }
},
{
  "type": "way",
  "id": 380117119,
  "center": {
    "lat": 41.2869772,
    "lon": 1.7213990
  },
  "tags": {
    "highway": "residential",
    "name": "Passatge de les Quintanes"
  }
},
{
  "type": "way",
  "id": 622018377,
  "center": {
    "lat": 40.7100765,
    "lon": 0.5706093
  },
  "tags": {
    "highway": "residential",
    "name": "Parc de les Quintanes"
  }
},
{
  "type": "way",
  "id": 9461267392,
  "center": {
    "lat": 41.2880650,
    "lon": 1.7188755
  },
  "tags": {
    "place": "hamlet",
    "name": "les Quintanes"
  }
},
{
  "type": "way",
  "id": 145029985,
  "center": {
    "lat": 42.3084297,
    "lon": 3.1477651
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer els Quintans"
  }
},
{
  "type": "way",
  "id": 1116043857,
  "center": {
    "lat": 42.2910313,
    "lon": 2.3506312
  },
  "tags": {
    "landuse": "meadow",
    "name": "Quintans de Can Pascal"
  }
},
{
  "type": "way",
  "id": 1116043869,
  "center": {
    "lat": 42.3099073,
    "lon": 2.3585622
  },
  "tags": {
    "landuse": "meadow",
    "name": "el Quintà del Cal Moner"
  }
},
{
  "type": "way",
  "id": 21292184,
  "center": {
    "lat": 41.6975150,
    "lon": 2.2021272
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Camí de Can Quintanes"
  }
},
{
  "type": "way",
  "id": 175766141,
  "center": {
    "lat": 41.7008210,
    "lon": 2.2155691
  },
  "tags": {
    "building": "yes",
    "name": "Can Quintanes"
  }
},
{
  "type": "way",
  "id": 183654898,
  "center": {
    "lat": 41.2448170,
    "lon": 1.4872583
  },
  "tags": {
    "highway": "living_street",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 200205095,
  "center": {
    "lat": 41.2867586,
    "lon": 1.7217323
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda de les Quintanes"
  }
},
{
  "type": "way",
  "id": 209196727,
  "center": {
    "lat": 41.5123418,
    "lon": 1.3661348
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 222195940,
  "center": {
    "lat": 41.2816521,
    "lon": 1.5526254
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 262792558,
  "center": {
    "lat": 41.2257661,
    "lon": 1.3116041
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 380117113,
  "center": {
    "lat": 41.2874569,
    "lon": 1.7215296
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 380117115,
  "center": {
    "lat": 41.2871541,
    "lon": 1.7209589
  },
  "tags": {
    "highway": "residential",
    "name": "Plaça de les Quintanes"
  }
},
{
  "type": "way",
  "id": 380117119,
  "center": {
    "lat": 41.2870411,
    "lon": 1.7212357
  },
  "tags": {
    "highway": "residential",
    "name": "Passatge de les Quintanes"
  }
},
{
  "type": "way",
  "id": 571407917,
  "center": {
    "lat": 41.5702682,
    "lon": 1.4401223
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 622018377,
  "center": {
    "lat": 40.7110433,
    "lon": 0.5697956
  },
  "tags": {
    "leisure": "park",
    "name": "Parc de les Quintanes"
  }
},
{
  "type": "way",
  "id": 686213498,
  "center": {
    "lat": 41.2262310,
    "lon": 1.3123062
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de les Quintanes"
  }
},
{
  "type": "way",
  "id": 1026137920,
  "center": {
    "lat": 42.0346695,
    "lon": 2.2064199
  },
  "tags": {
    "building": "yes",
    "name": "Mas Quintanes"
  }
},
{
  "type": "way",
  "id": 133743169,
  "center": {
    "lat": 42.3204004,
    "lon": 2.3415915
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Quintà"
  }
},
{
  "type": "way",
  "id": 133788202,
  "center": {
    "lat": 42.3220178,
    "lon": 2.3391225
  },
  "tags": {
    "highway": "residential",
    "name": "Passatge del Quintà"
  }
},
{
  "type": "way",
  "id": 1132009206,
  "center": {
    "lat": 42.3380230,
    "lon": 2.3534764
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Can Darrer"
  }
},
{
  "type": "way",
  "id": 1132009187,
  "center": {
    "lat": 42.3250862,
    "lon": 2.3382196
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de les Vesseganyes"
  }
},
{
  "type": "way",
  "id": 1132009191,
  "center": {
    "lat": 42.3248579,
    "lon": 2.3314226
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Vinardell"
  }
},
{
  "type": "way",
  "id": 1132009190,
  "center": {
    "lat": 42.3216153,
    "lon": 2.3289506
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintans de Can Sau"
  }
},
{
  "type": "way",
  "id": 1132009201,
  "center": {
    "lat": 42.3304016,
    "lon": 2.3273409
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Dalt"
  }
},
{
  "type": "way",
  "id": 1132009202,
  "center": {
    "lat": 42.3327405,
    "lon": 2.3303268
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Baix"
  }
},
{
  "type": "way",
  "id": 1131996721,
  "center": {
    "lat": 42.3547179,
    "lon": 2.3560027
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Casa Solà"
  }
},
{
  "type": "way",
  "id": 1131996722,
  "center": {
    "lat": 42.3536460,
    "lon": 2.3582406
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de Baix"
  }
},
{
  "type": "way",
  "id": 1131996731,
  "center": {
    "lat": 42.3559174,
    "lon": 2.3455413
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà del Rodà"
  }
},
{
  "type": "way",
  "id": 1132009185,
  "center": {
    "lat": 42.3241932,
    "lon": 2.3354640
  },
  "tags": {
    "landuse": "farmland",
    "name": "el Quintà"
  }
},
{
  "type": "way",
  "id": 1132010043,
  "center": {
    "lat": 42.3424050,
    "lon": 2.3980208
  },
  "tags": {
    "landuse": "farmland",
    "name": "Quintà de l'Avellaneda"
  }
},
{
  "type": "way",
  "id": 1132010044,
  "center": {
    "lat": 42.3569395,
    "lon": 2.3840635
  },
  "tags": {
    "landuse": "farmland",
    "name": "el Quintà"
  }
}
];
return ways;
}

export default llista_ways;