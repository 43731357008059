import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import {Icon, Style} from 'ol/style';
import {LineString, Point} from 'ol/geom';
import {getVectorContext} from 'ol/render';
import {fromLonLat} from 'ol/proj';
import {Tile as TileLayer, Vector as VectorLayer, Group as LayerGroup} from 'ol/layer';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import Overlay from 'ol/Overlay';
import {FullScreen, defaults as defaultControls} from 'ol/control';
  
//importació dels nodes
import llista_nodes from './nodes_combinats.js';
import llista_ways from './ways_combinats.js';

/**
 * Elements that make up the popup.
 */
var container = document.getElementById('popup');
var content = document.getElementById('popup-content');
var closer = document.getElementById('popup-closer');

/**
 * Create an overlay to anchor the popup to the map.
 */
var overlay = new Overlay({
  element: container,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};


var features_nodes = new Array();
var features_nodes_foto = new Array();

let nodes = llista_nodes();
//console.log(.length);
for (let i=0; i<nodes.length; i++) {
  var txt = "";
  var obj_tags = nodes[i].tags;
  for (var key in obj_tags) {
    //console.log(key);
    //obj_tags.hasOwnProperty(key)
    //console.log(obj_tags[key]);

    switch(obj_tags[key]) {
      case "Quintana 3":  //els casos particulars els posem davant dels casos genèrics
        txt = "Hidrant d'aigua";
        break;  
      case "Can Quintana":
        txt = "Masia (enderrocada el 1958)";
        break;
      case "locality":
        txt = "Lloc";
        break;
      case "tree":
        txt = "Arbre";
        break;
      case "cave_entrance":
        txt = "Cova";
        break;
      case "house":
        txt = "Casa";
        break;
      case "isolated_dwelling":
        txt = "Casa";
        break;
      case "hamlet":
        txt = "Casa";
        break;
    }
  }
  //if (txt!="") {
    //console.log(txt);
    //break;
  if (txt=="") { //els que falten
    console.log(nodes[i].tags.name)
    console.log(nodes[i].lat)
    console.log(nodes[i].lon)
    console.log("")
  }
  var tipus=txt;
  //console.log(tipus);

  var feature_node = new Feature({
    'geometry': new Point(fromLonLat([nodes[i].lon, nodes[i].lat])),
    'name': nodes[i].tags.name,
    'foto': nodes[i].tags.foto,
    'tipus': tipus
  });
  //if (nodes[i].tags.name.indexOf("Quintanes")>=0) {
  if (typeof nodes[i].tags.foto !== "undefined") {
    features_nodes_foto.push(feature_node);
  } else {
    features_nodes.push(feature_node);
  }
  //}
}

var features_ways = new Array();
var features_ways_foto = new Array();

let ways = llista_ways();
for (let i=0; i<ways.length; i++) {
  var txt = "";
  var obj_tags = ways[i].tags;
  for (var key in obj_tags) {
    //console.log(key);
    //obj_tags.hasOwnProperty(key)
    //console.log(obj_tags[key]);

    switch(obj_tags[key]) { //els casos particulars els posem davant dels casos genèrics
      case "Casa Quintana":
        txt = "Casa";
        break;
      case "la Quintana":
        txt = "Casa";
        break;
      case "Pont de la Quintana":
        txt = "Pont";
        break;
      case "Camí de la Quintana":
        txt = "Camí";
        break; 
      case "Plaça de la Quintana":
        txt = "Plaça";
        break;
      case "La Quintana (masia)":
        txt = "Casa";
        break;
      case "Pujada de la Quintana":
        txt = "Camí";
        break;
      case "carretera de Mas Quintà":
        txt = "Carrer";
        break;
      case "Mas de la Quintana":
        txt = "Casa";
        break;
      case "La Quintana del Grau":
        txt = "Casa";
        break;
      case "Cabana de Cal Quintana":
        txt = "Casa";
        break;
      case "Mas de Quintana":
        txt = "Casa";
        break;
      case "Mas Quintanes":
        txt = "Casa";
        break;
      case "Can Quintanes":
        txt = "Casa";
        break;
      case "house":
        txt = "Casa";
        break;
      case "isolated_dwelling":
        txt = "Casa";
        break;
      case "park":
        txt = "Parc";
        break;
      case "stream":
        txt = "Riu";
        break;
      case "pedestrian":
        txt = "Carrer";
        break;
      case "residential":
        txt = "Carrer";
        break;
      case "living_street":
        txt = "Carrer";
        break;
      case "footway":
        txt = "Camí";
        break;
      case "residential":
        txt = "Carrer";
        break;
      case "footway":
        txt = "Camí";
        break;
      case "farm":
        txt = "Casa";
        break;
      case "farmland": //zona agrícola, associada a una casa. Per ex: Quintana de Vilardebò
        txt = "Casa";
        break;
      case "industrial":
        txt = "Polígon";
        break;
      case "steps":
        txt = "Escales";
        break;     
      case "meadow":
        txt = "Prat";
        break;
      case "garden":
        txt = "Jardí";
        break;
      case "hamlet":
        txt = "Llogaret";
        break;
    }
  }
  //if (txt!="") {
    //console.log(txt);
    //break;
  if (txt=="") { //els que falten
    console.log(ways[i].tags.name)
    console.log(ways[i].center.lat)
    console.log(ways[i].center.lon)
    console.log("")
  }
  var tipus=txt;
  //console.log(tipus);
  var feature_way = new Feature({
    'geometry': new Point(fromLonLat([ways[i].center.lon, ways[i].center.lat])),
    'name': ways[i].tags.name,
    'foto': ways[i].tags.foto,
    'tipus': tipus
  });
  //if (ways[i].tags.name.indexOf("Quintanes")>=0) {
  if (typeof ways[i].tags.foto !== "undefined") {
    features_ways_foto.push(feature_way);
  } else {
    features_ways.push(feature_way);
  }
  //}
}

var iconStyle = new Style({
  image: new Icon({
    anchor: [0.5,20],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'img/lletraq_24.png'
  })
});

var iconStyleFoto = new Style({
  image: new Icon({
    anchor: [0.5,20],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'img/lletraq_24_foto.png'
  })
});

var vectorSourceNodes = new VectorSource({
  features: features_nodes,
  wrapX: false,
});

var vectorSourceNodesFoto = new VectorSource({
  features: features_nodes_foto,
  wrapX: false,
});

var vectorNode = new VectorLayer({
  source: vectorSourceNodes,
  style: iconStyle,
});

var vectorNodeFoto = new VectorLayer({
  source: vectorSourceNodesFoto,
  style: iconStyleFoto,
});

var vectorSourceWays = new VectorSource({
  features: features_ways,
  wrapX: false,
});

var vectorSourceWaysFoto = new VectorSource({
  features: features_ways_foto,
  wrapX: false,
});

var vectorWay = new VectorLayer({
  source: vectorSourceWays,
  style: iconStyle,
});

var vectorWayFoto = new VectorLayer({
  source: vectorSourceWaysFoto,
  style: iconStyleFoto,
});

var layersOSM = new LayerGroup({
  layers: [
    new TileLayer({
      title: 'OSM',
      source: new OSM({
        layer: 'OSM'
      }),
    }),
    vectorNode,
    vectorWay,
    vectorNodeFoto,
    vectorWayFoto
  ]
});

var layersTopoTresc = new LayerGroup({
  layers: [
    new TileLayer({
      title: 'Topotresc',
      source: new XYZ({
        attributions: 'Map data <a href="https://www.topotresc.com/" target="_blank">TopoTresk</a> by <a href="https://github.com/aresta/topotresc" target="_blank">aresta</a>',
        url: 'https://api.topotresc.com/tiles/{z}/{x}/{y}'
      }),
    }),
    vectorNode,
    vectorWay,
    vectorNodeFoto,
    vectorWayFoto
  ]
});


var map = new Map({
  controls: defaultControls().extend([new FullScreen()]),
  layers: [
    new TileLayer({
      source: new OSM({
        layer: 'OSM'
      })
    }),
    vectorNode,
    vectorWay,
    vectorNodeFoto,
    vectorWayFoto
  ],
  overlays: [overlay],
  target: 'map',
  view: new View({
    center: fromLonLat([1.9858, 41.9176]),
    zoom: 9
  })
});

//https://openlayers.org/en/latest/examples/popup.html
//https://stackoverflow.com/questions/26391195/adding-event-handler-to-feature-in-openlayers-3
map.on("click", function(e) {
    var coordinate = e.coordinate;
    map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        //console.log(feature.values_.name);
        content.innerHTML = '<code>' + feature.values_.name + '<br />(' + feature.values_.tipus + ')';
        if (typeof feature.values_.foto !== "undefined") {
          content.innerHTML += "<br /><img src='./fotos/" + feature.values_.foto + "' width='230'><br />";
        }
        content.innerHTML += '</code>';
        overlay.setPosition(coordinate);
    })
});

map.on("pointermove", function(e) {
  map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
    if (feature.values_.name != undefined) {
      document.getElementById('tooltip').style.visibility="visible";
      var content = document.getElementById('tooltip-content');
      content.innerHTML = '<code>' + feature.values_.name + '</code>';
      setTimeout(function(){ tancar_tooltip(); }, 2000);
    }
  })
});

function tancar_tooltip() {
  document.getElementById('tooltip').style.visibility = "hidden";
}

function setMapType(newType) {

    if(newType == 'OSM') {
        map.setLayerGroup(layersOSM);
    } else if (newType == 'TRESC') {
        map.setLayerGroup(layersTopoTresc);
    } 
    

}

//events
var el_osm = document.getElementById("osm");
el_osm.addEventListener("click", function() { setMapType('OSM')}, false);

var el_tresc = document.getElementById("tresc");
el_tresc.addEventListener("click", function() { setMapType('TRESC')}, false);
