// al final de tot hi ha un export
function llista_nodes() {
var nodes = [
{
  "type": "node",
  "id": 1241552139,
  "lat": 41.7944265,
  "lon": 2.1054400,
  "tags": {
    "name": "Quintana de la Gónima",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241559289,
  "lat": 41.6974443,
  "lon": 2.2184781,
  "tags": {
    "name": "Serrat de la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241569776,
  "lat": 41.9238390,
  "lon": 2.3127377,
  "tags": {
    "name": "Pla de la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241571928,
  "lat": 41.4921858,
  "lon": 1.7902390,
  "tags": {
    "name": "Vinya del Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241591287,
  "lat": 42.0789544,
  "lon": 2.7786387,
  "tags": {
    "name": "la Quintana",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241592925,
  "lat": 42.2419917,
  "lon": 2.6044414,
  "tags": {
    "name": "La Quintana",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241599693,
  "lat": 42.1174963,
  "lon": 2.9065499,
  "tags": {
    "name": "la Quintana",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241601215,
  "lat": 42.1422856,
  "lon": 2.7457033,
  "tags": {
    "name": "la Quintana",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241784463,
  "lat": 41.0263030,
  "lon": 0.7679646,
  "tags": {
    "name": "Miloques de Quintana",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 2595422186,
  "lat": 41.7504671,
  "lon": 1.3762158,
  "tags": {
    "name": "la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 3244493219,
  "lat": 41.7367429,
  "lon": 1.9757763,
  "tags": {
    "name": "Roure de la Quintana",
    "natural": "tree"
  }
},
{
  "type": "node",
  "id": 4054804049,
  "lat": 42.0337186,
  "lon": 3.1323373,
  "tags": {
    "name": "Horta d'en Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 4784477096,
  "lat": 41.6700184,
  "lon": 1.9865382,
  "tags": {
    "name": "Avenc de la Quintana de Sant Jaume",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 4876272828,
  "lat": 41.5148436,
  "lon": 1.5369425,
  "tags": {
    "building": "house",
    "name": "Cal Quintana"
  }
},
{
  "type": "node",
  "id": 5242833204,
  "lat": 41.9279404,
  "lon": 2.0509924,
  "tags": {
    "name": "la Quintana",
    "place": "isolated_dwelling"
  }
},
{
  "type": "node",
  "id": 5592297941,
  "lat": 41.6316165,
  "lon": 2.3485853,
  "tags": {
    "name": "la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 5592433296,
  "lat": 41.6586371,
  "lon": 2.2652763,
  "tags": {
    "name": "Quintana de Can Draper",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 5749888314,
  "lat": 41.3679703,
  "lon": 1.8001217,
  "tags": {
    "emergency": "fire_hydrant",
    "fire_hydrant:position": "sidewalk",
    "fire_hydrant:type": "underground",
    "name": "Quintana 3",
    "operator": "003",
    "ref": "340 con Quintana",
    "survey:date": "2018-06-29",
    "water_source": "main"
  }
},
{
  "type": "node",
  "id": 6262006105,
  "lat": 41.3661132,
  "lon": 1.8024615,
  "tags": {
    "name": "la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7321470853,
  "lat": 41.7940189,
  "lon": 2.0167574,
  "tags": {
    "name": "Quintana de Reixac",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7327938144,
  "lat": 41.7672993,
  "lon": 1.9837133,
  "tags": {
    "name": "Quintana de Bellveí",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7335170095,
  "lat": 41.7586802,
  "lon": 2.0771386,
  "tags": {
    "name": "Quintana de Vilanova",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7437735378,
  "lat": 42.0904912,
  "lon": 2.3039640,
  "tags": {
    "name": "Quintanada del Veïnat del Serrat",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7497307203,
  "lat": 42.0882145,
  "lon": 2.5841598,
  "tags": {
    "name": "la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7551640309,
  "lat": 41.6785347,
  "lon": 2.1359083,
  "tags": {
    "name": "Quintana del Fonoll",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513955,
  "lat": 42.1416160,
  "lon": 2.2743307,
  "tags": {
    "name": "Quintana del Pla de Marenyol",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513957,
  "lat": 42.1406515,
  "lon": 2.2678892,
  "tags": {
    "name": "Quintana de les Tombes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8318343345,
  "lat": 42.1359411,
  "lon": 2.2131806,
  "tags": {
    "name": "Quintana de Sant Moi",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8483122505,
  "lat": 41.7194837,
  "lon": 1.7000498,
  "tags": {
    "name": "Quintana del Pujolar",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241548189,
  "lat": 42.0210004,
  "lon": 1.9997371,
  "tags": {
    "name": "Quintà de Borrelleres",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241549348,
  "lat": 42.0236142,
  "lon": 2.0144407,
  "tags": {
    "name": "Quintà de Rocamentidera",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241550502,
  "lat": 42.0716572,
  "lon": 2.0392925,
  "tags": {
    "name": "el Quintà",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241552592,
  "lat": 42.0781157,
  "lon": 2.2707580,
  "tags": {
    "name": "Quintà d'en Guspinera",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241554684,
  "lat": 42.0229619,
  "lon": 2.0780875,
  "tags": {
    "name": "Quintà de Vilatammar",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241556975,
  "lat": 42.0663795,
  "lon": 2.3391493,
  "tags": {
    "name": "Quintà Vell",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241558513,
  "lat": 42.0417353,
  "lon": 2.0041241,
  "tags": {
    "name": "Quintà de Cal Pieret",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241564283,
  "lat": 42.0244911,
  "lon": 2.0959791,
  "tags": {
    "name": "Quintà de Bosquets",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241564313,
  "lat": 42.0740408,
  "lon": 2.2519221,
  "tags": {
    "name": "Quintà de Comadebó",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241565408,
  "lat": 42.1155093,
  "lon": 2.1027182,
  "tags": {
    "name": "el Quintà",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241567283,
  "lat": 42.0631472,
  "lon": 2.0823771,
  "tags": {
    "name": "Quintà de Gonfaus",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241568971,
  "lat": 42.0046087,
  "lon": 2.2233682,
  "tags": {
    "name": "Quintà de la Roca",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241570221,
  "lat": 42.1133610,
  "lon": 2.1301829,
  "tags": {
    "name": "Quintà de Mas d'Amunt",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241572475,
  "lat": 42.1893993,
  "lon": 2.0503600,
  "tags": {
    "name": "Quintà de les Planes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241584998,
  "lat": 42.0679633,
  "lon": 2.5703822,
  "tags": {
    "name": "Quintà de l'Armenguer",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241586976,
  "lat": 42.0548658,
  "lon": 2.8344938,
  "tags": {
    "name": "el Quintà",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241589620,
  "lat": 42.3963662,
  "lon": 2.7573113,
  "tags": {
    "name": "Roquetes del Quintà",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241591849,
  "lat": 41.9276400,
  "lon": 2.8640685,
  "tags": {
    "name": "Quintà del Morell",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 5499473308,
  "lat": 41.6675723,
  "lon": 2.5143714,
  "tags": {
    "name": "Roure del Quintà",
    "natural": "tree",
    "species": "Quercus cerrioides",
    "species:ca": "roure"
  }
},
{
  "type": "node",
  "id": 7444116476,
  "lat": 42.2208783,
  "lon": 2.1209663,
  "tags": {
    "name": "Quintà de la Cabana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7444116478,
  "lat": 42.2107257,
  "lon": 2.1195963,
  "tags": {
    "name": "Quintà del Colomer",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7498149710,
  "lat": 42.1029048,
  "lon": 2.5599433,
  "tags": {
    "name": "Quintà de la Plana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7547746369,
  "lat": 42.0041501,
  "lon": 2.4425085,
  "tags": {
    "name": "Quintà del Solell",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8560922666,
  "lat": 42.0777560,
  "lon": 2.4115227,
  "tags": {
    "name": "Quintà de la Faja",
    "place": "locality"
  }
},
{
  "type": "node",
  "lat": 41.43784,
  "lon": 2.16161,
  "tags": {
    "name": "Can Quintana",
    "building": "yes"
  }
},
{
  "type": "node",
  "id": 9234777163,
  "lat": 42.2312676,
  "lon": 2.0876951,
  "tags": {
    "name": "Quintà de Bartés",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8842492761,
  "lat": 42.2015890,
  "lon": 2.0052079,
  "tags": {
    "name": "Quintà de la Qüestió",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8842492762,
  "lat": 42.2035091,
  "lon": 1.9876357,
  "tags": {
    "name": "Quintà de la Teuleria",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8842492778,
  "lat": 42.2068538,
  "lon": 1.9658699,
  "tags": {
    "name": "Quintà d'Ardericó",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8845849262,
  "lat": 42.1969348,
  "lon": 2.0572665,
  "tags": {
    "name": "Quintà de Caselles",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8910004137,
  "lat": 41.8464193,
  "lon": 2.3751725,
  "tags": {
    "name": "Quintà dels Molins",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 9234777163,
  "lat": 42.2312676,
  "lon": 2.0876951,
  "tags": {
    "name": "Quintà de Bartés",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 9430579091,
  "lat": 42.1680238,
  "lon": 2.0886589,
  "tags": {
    "name": "Quintà dels Camps",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241560536,
  "lat": 42.0335402,
  "lon": 2.1949278,
  "tags": {
    "name": "Pla de Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241585710,
  "lat": 42.0437079,
  "lon": 2.7674628,
  "tags": {
    "name": "Quintanes de l'Uric",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241590210,
  "lat": 42.1410410,
  "lon": 2.8885335,
  "tags": {
    "name": "les Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241596331,
  "lat": 42.0904519,
  "lon": 2.9890668,
  "tags": {
    "name": "Bosc d'en Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241601374,
  "lat": 42.0757901,
  "lon": 2.8713713,
  "tags": {
    "name": "les Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 5194249813,
  "lat": 42.4865926,
  "lon": 2.7222458,
  "tags": {
    "name": "Les Quintanes Nord",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8431585106,
  "lat": 41.7009522,
  "lon": 2.2145731,
  "tags": {
    "name": "Camps de Can Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 9431596258,
  "lat": 41.7051158,
  "lon": 2.2193509,
  "tags": {
    "name": "Grauet de Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241562246,
  "lat": 42.1256735,
  "lon": 2.1543575,
  "tags": {
    "name": "Quintans del Boixader",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241599117,
  "lat": 42.1364385,
  "lon": 2.1486230,
  "tags": {
    "name": "Quintans de les Falgoses",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7498149715,
  "lat": 42.0963502,
  "lon": 2.5741930,
  "tags": {
    "name": "Quintans de la Teida",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513944,
  "lat": 42.1357908,
  "lon": 2.2349168,
  "tags": {
    "name": "Quintans de Beví Xic",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513947,
  "lat": 42.1373882,
  "lon": 2.2465265,
  "tags": {
    "name": "Quintans del Beví Gros",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513950,
  "lat": 42.1360785,
  "lon": 2.2590441,
  "tags": {
    "name": "Quintans de Coromines",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513951,
  "lat": 42.1364036,
  "lon": 2.2554134,
  "tags": {
    "name": "Quintans de Coromines",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8151513953,
  "lat": 42.1345960,
  "lon": 2.2654060,
  "tags": {
    "name": "Quintans dels Ferrers",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8318358896,
  "lat": 42.1152451,
  "lon": 2.2099922,
  "tags": {
    "name": "Quintans de la Solana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8842492759,
  "lat": 42.2026029,
  "lon": 2.0116972,
  "tags": {
    "name": "Quintans de Santa Eugènia",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8845849255,
  "lat": 42.1843669,
  "lon": 2.0645115,
  "tags": {
    "name": "Quintà de la Riba",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8845849255,
  "lat": 41.9716,
  "lon": 2.0189,
  "tags": {
    "name": "Quintà del Blanquer",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 10273487724,
  "lat": 42.20443,
  "lon": 1.95980,
  "tags": {
    "name": "Quintà de la Baga",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 10273542054,
  "lat": 42.13213,
  "lon": 2.39858,
  "tags": {
    "name": "Quintà de Santa Magdalena",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 10286526406,
  "lat": 41.6888384,
  "lon": 2.1631974,
  "tags": {
    "name": "la Quintana",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241560536,
  "lat": 42.0335402,
  "lon": 2.1949278,
  "tags": {
    "name": "Pla de Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241565675,
  "lat": 42.0308601,
  "lon": 2.1942005,
  "tags": {
    "name": "Baga de Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241585710,
  "lat": 42.0437079,
  "lon": 2.7674628,
  "tags": {
    "name": "Quintanes de l'Uric",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241590210,
  "lat": 42.1410410,
  "lon": 2.8885335,
  "tags": {
    "name": "les Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241596331,
  "lat": 42.0904519,
  "lon": 2.9890668,
  "tags": {
    "name": "Bosc d'en Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241601374,
  "lat": 42.0757901,
  "lon": 2.8713713,
  "tags": {
    "name": "les Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8431585106,
  "lat": 41.7009522,
  "lon": 2.2145731,
  "tags": {
    "name": "Camps de Can Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 9431596258,
  "lat": 41.7051158,
  "lon": 2.2193509,
  "tags": {
    "name": "Grauet de Quintanes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 9461267392,
  "lat": 41.2880650,
  "lon": 1.7188755,
  "tags": {
    "name": "Les Quintanes",
    "place": "hamlet"
  }
},
{
  "type": "node",
  "id": 9764802664,
  "lat": 41.6685847,
  "lon": 1.9864270,
  "tags": {
    "name": "Roure Ajagut de les Quintanes de Sant Jaume",
    "natural": "tree"
  }
}
];
return nodes;
}

export default llista_nodes;
